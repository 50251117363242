.mvp-container {
    min-height: 100vh;
    background: url('../../assets/mvp-bg.png') center top no-repeat;
    background-size: 100vw auto;

    .exchange-wrapper {
        margin: 0 auto;
        padding-top: 7.8vw;
        width: 87.36vw;

        .input-wrapper {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 52.65vw;
            height: 9.36vw;
            background: url('../../assets/exchange-input-bg.png') center top no-repeat;
            background-size: 52.65vw 9.36vw;

            .code-input {
                position: absolute;
                top: 50%;
                right: 2vw;
                transform: translate(0, -50%);
                width: 35vw;
                background: none;
                border: none;
                outline: none;
            }
        }

        .btn {
            display: inline-block;
            margin-left: 2vw;
            vertical-align: top;
            width: 28.21vw;
            height: 9.36vw;
        }
    }

    .city-name {
        margin: 12.35vw auto 0;
        width: 30vw;
        font-size: 5.2vw;
        color: #e2c276;
        text-align: center;
        /* background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#e2c276), to(#f2e5bf)); */
        background: -webkit-linear-gradient(left, #e2c276, #f2e5bf);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .mvp-wrapper {
        margin: 5.2vw auto 0;
        width: 95.7vw;
        height: 98vw;
        overflow-y: scroll;

        .one-mvp {
            position: relative;
            margin-bottom: 1.95vw;
            float: left;
            width: 46.55vw;
            height: 28.6vw;
            background: url('../../assets/mvp-wrapper-left-bg.png') center center no-repeat;
            background-size: 43.55vw 28.6vw;
            color: #ffffff;

            .wrapper {
                padding: 2vw 1vw 2vw 3.5vw;

                .cover {
                    float: left;
                    width: 17.55vw;
                    height: 24.44vw;
                }

                .num-wrapper {
                    display: none;
                    position: absolute;
                    bottom: 2vw;
                    width: 17.55vw;
                    height: 2.6vw;
                    line-height: 2.6vw;
                    background-image: linear-gradient(0deg, #a7462b, #70251d);
                    color: #f3e7c9;
                    text-align: center;
                    font-size: 2.08vw;
                }

                .right-content {
                    margin-top: 2vw;
                    margin-left: 1.25vw;
                    width: 22vw;
                    float: left;
                    font-size: 2.34vw;
                    line-height: 2;

                    .name,
                    .city,
                    .pos {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .iconChoose {
                display: none;
                position: absolute;
                top: -1px;
                right: 2px;
                width: 4.55vw;
                height: 4.55vw;
            }

        }

        .one-mvp:nth-child(2n) {
            background: url('../../assets/mvp-wrapper-right-bg.png') center center no-repeat;
            background-size: 43.55vw 28.6vw;

            .wrapper {
                padding: 2vw 1vw 2vw 2.5vw;
            }

        }

    }

    .buy-label {
        position: absolute;
        top: 142vw;
        left: 50%;
        transform: translate(-50%, 0);

        .wrapper {
            width: 56.68vw;
            height: 9.1vw;
            line-height: 9.1vw;
            background-color: #22536a;
            border: 1px solid #faecb3;
            border-radius: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 2.86vw;
        }

        .link-label {
            display: flex;
            margin-top: 1.3vw;

            .link {
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .btn {
                padding: 0 1vw;
                flex: 0 0 10vw;
                border-left: 1px solid #ffffff;

            }
        }
    }

    .confirm-btn {
        position: absolute;
        top: 176vw;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        width: 34.19vw;
        height: 11.31vw;
    }

    .success-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);

        .success-wrapper {
            display: block;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 73.19vw;
            height: 106.08vw;
        }

        .continue-btn {
            position: absolute;
            top: 37%;
            left: 50%;
            transform: translate(-50%, -37%);
            width: 22.36vw;
            height: 7.28vw;
        }

        .return-btn {
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -43%);
            width: 22.36vw;
            height: 7.28vw;
        }
    }

    .buy-wrapper {
        position: absolute;
        top: 136.4vw;
        left: 50%;
        transform: translate(-50%);
        width: 57.2vw;
        height: 9.36vw;
    }
}