.form-container {
    min-height: 100vh;
    background: url('../../assets/form-bg.png') center top no-repeat;
    background-size: 100vw auto;

    .form-wrapper {
        position: absolute;
        top: 22.75vw;
        left: 50%;
        transform: translate(-50%, 0);
        width: 53.04vw;

        .avatar-wrapper {
            position: relative;
            margin: 0 auto 10.4vw;
            width: 39vw;
            height: 59.15vw;
            text-align: center;
            overflow: hidden;

            .avatar-bg {
                display: block;
                width: 39vw;
                height: 59.15vw;
                background: url('../../assets/avatar-bg.png') center center no-repeat;
                background-size: 39vw 59.15vw;
            }

            input {
                position: absolute;
                font-size: 15.34vw;
                right: 0;
                top: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 1000;
            }

            .avatar {
                position: absolute;
                top: 2.6vw;
                left: 0;
                right: 0;
                bottom: 0;
                width: 39vw;
                height: 59.15vw;
                z-index: 999;
            }
        }

        .input-wrapper {
            margin-bottom: 3.9vw;
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 52.65vw;
            height: 9.36vw;
        }

        .name-wrapper {
            background: url('../../assets/name-input-bg.png') center top no-repeat;
            background-size: 52.65vw 9.36vw;
        }

        .school-wrapper {
            background: url('../../assets/school-input-bg.png') center top no-repeat;
            background-size: 52.65vw 9.36vw;
        }

        .desc-wrapper {
            background: url('../../assets/desc-input-bg.png') center top no-repeat;
            background-size: 52.65vw 9.36vw;
        }

        .input {
            position: absolute;
            top: 50%;
            right: 2vw;
            transform: translate(0, -50%);
            width: 35vw;
            background: none;
            border: none;
            outline: none;
        }
    }

    .submit-btn {
        position: absolute;
        top: 148vw;
        left: 50%;
        transform: translate(-50%, 0);
        margin-bottom: 20vw;
        display: block;
        width: 34.19vw;
        height: 11.31vw;
    }

    .success-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);

        .success-wrapper {
            display: block;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 73.19vw;
            height: 106.08vw;
        }

        .return-btn {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);
            width: 22.36vw;
            height: 7.28vw;
        }
    }
}