.home-container {
    min-height: 100vh;
    background: url('../../assets/home-bg.png') center top no-repeat;
    background-size: 100vw auto;

    .action-wrapper {
        margin-bottom: 10vw;
        position: absolute;
        top: 92.8vw;
        left: 50%;
        transform: translate(-50%, 0);
        width: 54.6vw;

        img {
            display: block;
        }

        .left {
            float: left;
        }

        .right {
            float: right;
        }

        .icon {
            width: 21.06vw;
            height: 20.28vw;
        }

        .btn {
            margin-top: 1.95vw;
            width: 22.32vw;
            height: 7.28vw;
        }
    }

    .rule {
        position: absolute;
        right: 2.08vw;
        top:79vw;
        width: 7.8vw;
        height: 7.8vw;
    }

    .rule-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);

        .rule-bg {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 73.97vw;
            height: 125.71vw;
            background: url('../../assets/rule-modal.png') center center no-repeat;
            background-size: 73.97vw 125.71vw;
        }

        .close-btn {
            position: absolute;
            top: 2vw;
            right: 3vw;
            width: 3.9vw;
            height: 3.9vw;
            line-height: 3.9vw;
            text-align: center;
            font-size: 1.82vw;
            color: #c5dbe4;
            border: 2px solid #c5dbe4;
            border-radius: 50%;
        }
    }
}