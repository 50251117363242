.city-container {
    min-height: 100vh;
    background: url('../../assets/city-bg.png') center top no-repeat;
    background-size: 100vw auto;

    .city-wrapper {
        position: absolute;
        top: 28vw;
        left: 50%;
        transform: translate(-50%, 0);
        width: 65vw;
        height: 125vw;
        overflow-y: scroll;

        .one {
            position: relative;
            margin: 0 auto 5.2vw;
            padding: 0.78vw;
            width: 53.04vw;
            height: 25.22vw;
            background-image: linear-gradient(0deg, #152a34, #14516d);
            border: 1px solid #f0c55f;
            font-size: 6.8vw;
            font-weight: 800;
            color: #565555;

            .city-cover {
                width: 100%;
                height: 100%;
            }

            .name {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .tip {
                position: absolute;
                top: 80%;
                left: 50%;
                transform: translate(-50%, -80%);
                font-size: 1.56vw;
                width: 100%;
                text-align: center;
            }

            .mask {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }
}