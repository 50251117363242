body {
    background-color: #0e1b23;
}

.clearfix {
    display: block;
}

.clearfix::after {
    content: ' ';
    display: block;
    height: 0;
    line-height: 0;
    clear: both;
    visibility: hidden;
}